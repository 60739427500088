import CIcon from '@coreui/icons-react';
import { CInput, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react';
import React from 'react'

function InputField({
    icon,
    type,
    name,
    placeholder,
    handleChange,
}) {
    return (
        <CInputGroup className="mb-3">
            <CInputGroupPrepend>
                <CInputGroupText>
                    <CIcon
                        content={icon}
                        color="black"
                        className="mr-2"
                    />
                </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
                type={type}
                placeholder={placeholder}
                autoComplete={name}
                name={name}
                onChange={(event) => {
                    handleChange(event.target.value);
                }}
            />
        </CInputGroup>
    )
}

export default InputField