const Base_url_server = () => {
  let BaseURL = "/api";

  if (process.env.REACT_APP_SERVER !== "production") {
    return (BaseURL = "https://ebh-staging.herokuapp.com/api");
  } else {
    return BaseURL;
  }
};

export const environment = {
  baseURL: Base_url_server(),
  feBaseURL: process.env.REACT_APP_SERVER === "production" ? "https://ebh-prod.herokuapp.com" : "https://ebh-staging.herokuapp.com",
};
