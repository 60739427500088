import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import IdleTimer from 'react-idle-timer'
import { ClearLocalStorage } from "src/helpers/ClearLocalStorage";
import toast, { Toaster } from "react-hot-toast";
import { init, logEvent } from '@amplitude/analytics-browser';

//initialize LogRocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ForgotPassword from './views/pages/forgot_password/ForgotPassword';

init("d16992c930ee5a387092050fcb6987d5");

try {
  LogRocket.init('4pkmqo/ebh', {
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
    network: {
      responseSanitizer: response => {
        if (response.headers['x-secret']) {
          // removes all response data
          return null;
        }
        // scrubs response body
        response.body = null;
        return response;
      },
    },
    browser: {
      urlSanitizer: url => {
        let sanitizedUrl = url;

        // redact the path following /ssn/ from the URL
        sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^\/]*)/i, '/ssn/**redacted**');

        // redact the value of the query parameter secret_key
        sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

        // make sure you return the sanitized URL string
        return sanitizedUrl;
      },
    },
  });

} catch (error) {
  toast.error("LogRocket Error: " + error.message);
  // Additional error handling, if necessary
}


// after calling LogRocket.init()
setupLogRocketReact(LogRocket);


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// timeout for tab/browser close function
window.onbeforeunload = () => {
  window.localStorage.unloadTime = JSON.stringify(new Date());
};

window.onload = () => {
  let loadTime = new Date();
  let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
  let refreshTime = loadTime.getTime() - unloadTime.getTime();

  if (refreshTime > 1000 * 60 * 15)     //   5 minutes timeout
  {
    ClearLocalStorage();
  }
};

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));


// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));

class App extends Component {

  state = {
    token: localStorage.getItem('token'),
  }

  componentDidMount() {
    this.setState({
      token: localStorage.getItem('token'),
    })
  }

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  render() {
    return (
      <BrowserRouter>
        {/*    timeout function for idle screen   */}
        {this.state.token &&
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref
            }}
            timeout={1000 * 60 * 15} //   15 minutes timeout
            crossTab={{
              emitOnAllTabs: true
            }}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
        }
        <React.Suspense fallback={loading}>
          <Toaster />
          {!this.state.token ? (
            <Switch onPress={() => logEvent('user-login')}>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
              <Redirect from="/" to="/login" />
            </Switch>
          ) : (
            <Switch>
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          )}
        </React.Suspense>
      </BrowserRouter>
    );
  }

  handleOnIdle() {
    ClearLocalStorage();
  }

}

export default App;
