import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CCardGroup, CCardHeader, CCol, CContainer, CForm, CLink, CRow, CSpinner } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { freeSet } from "@coreui/icons";
import { ForgotPasswordApi, ResetPasswordApi, VerifyOtpApi } from 'src/api/api';
import InputField from './components/InputField';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { set } from 'lodash';

function ForgotPassword() {
    const [userEmail, setUserEmail] = useState(null);
    const [otp, setOtp] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [stepText, setStepText] = useState('Forgot Password');
    const [steps, setSteps] = useState(0);
    const [alert, setAlert] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [token, setToken] = useState(null);

    let history = useHistory();

    const sendOtp = () => {
        setAlert(null)
        try {

            ForgotPasswordApi({ email: userEmail })
                .then((res) => {
                    if (res.status === 200) {
                        setAlert(<div className="alert alert-success" role="alert">{res.data.message}</div>)
                        setToken(res.data.token);
                        setSteps(1);
                        setStepText('Verify Otp');
                    }
                    else {
                        throw new Error(res.data.message);
                    }
                })
                .catch((err) => {
                    setAlert(<div className="alert alert-danger" role="alert">{err?.response?.data?.message ?? err.message}</div>)
                })
        } catch (error) {
            console.log(error)

        }
    }

    const confirmPasswordChange = (confirmPasswordValue) => {
        setConfirmPassword(confirmPasswordValue);
        if (confirmPasswordValue !== password) {
            setAlert(<div className="alert alert-danger" role="alert">Password and Confirm Password does not match</div>)
        } else {
            setAlert(null)
        }
    }

    const submit = () => {
        setAlert(null)
        setIsLoading(true);
        if (steps === 0) {
            if (userEmail === null || userEmail === '') {
                setIsLoading(false);
                setAlert(<div className="alert alert-danger" role="alert">Please enter your email</div>)
                return;
            }
            sendOtp();

        } else if (steps === 1) {
            VerifyOtpApi({ email: userEmail, otp: otp })
                .then((res) => {
                    if (res.status === 200) {
                        setAlert(<div className="alert alert-success" role="alert">{res.data.message}</div>)
                        setSteps(2);
                        setStepText('Reset Password');
                    }
                    else {
                        throw new Error(res.data.message);
                    }
                })
                .catch((err) => {
                    setAlert(<div className="alert alert-danger" role="alert">{err?.response?.data?.message ?? err.message}</div>)
                })
        }
        else if (steps === 2) {
            ResetPasswordApi({ email: userEmail, password: password, token: token })
                .then((res) => {
                    if (res.status === 200) {
                        history.push('/login');
                        toast.success(`${res.data.message}`, {
                            position: "top-right",
                            duration: 1000,
                        });
                    }
                    else {
                        throw new Error(res.data.message);
                    }
                })


        } else {
            setSteps(0);
            setStepText('Send');
        }
        setIsLoading(false);
    }

    return (

        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="5">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardHeader>
                                    <Link
                                        to={{
                                            pathname: `/login`
                                        }}
                                    >
                                        <CButton className="m-1" color="link">
                                            <CIcon
                                                content={freeSet.cilArrowLeft}
                                                color="black"
                                                className="mr-2"
                                            />
                                            Back to Login
                                        </CButton>
                                    </Link>
                                </CCardHeader>
                                {alert}
                                <CCardBody>
                                    <CForm className='d-flex flex-column justify-content-center'>
                                        <h3>{stepText}</h3>
                                        <p className="text-muted mb-2">Enter your email</p>
                                        {
                                            steps === 0 ? (<InputField icon={freeSet.cilUser} type='email' name='email' placeholder='Email' handleChange={setUserEmail} />) :
                                                steps === 1 ? (
                                                    <>
                                                        <InputField
                                                            icon={freeSet.cilLockLocked}
                                                            type='text'
                                                            name='otp'
                                                            placeholder='Enter OTP Number'
                                                            handleChange={setOtp}
                                                        />
                                                        <CLink className="text-right mb-3" onClick={sendOtp}>Resend Otp</CLink>
                                                    </>
                                                ) :
                                                    steps === 2 ? (
                                                        <>
                                                            <InputField
                                                                icon={freeSet.cilLockLocked}
                                                                type='password'
                                                                name='password'
                                                                placeholder='Enter New Password'
                                                                handleChange={setPassword}
                                                            />
                                                            <InputField
                                                                icon={freeSet.cilLockLocked}
                                                                type='password'
                                                                name='confirmPassword'
                                                                placeholder='Confirm New Password'
                                                                handleChange={confirmPasswordChange}
                                                            />
                                                        </>
                                                    ) : null
                                        }
                                        <h2 className=''>{isLoading}</h2>
                                        <CButton disabled={isLoading} color="primary" className="col-12 block ml-auto mr-auto" onClick={submit}>
                                            {isLoading && <CSpinner color="white" size="sm" className="mr-2" />}
                                            {stepText}
                                        </CButton>

                                    </CForm>
                                </CCardBody>

                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default ForgotPassword